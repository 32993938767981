<template>
    <div class="billCheckReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天 <br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block orderChannel">
                    <div class="lable-txt">订单渠道：</div>
                    <div class="from-input" @click="orderChannelClick()">
                        <input type="text" placeholder="请选择" v-model="orderChannelName" readonly />
                        <i class="iconfont icon-yewuliucheng"></i>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="search-flex">
                        <div class="search-input"><input type="text" v-model="selectContent"  placeholder="单号、台号、收银员检索" /></div>
                        <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            <div class="tag-box">
                <div class="lable-txt">订单标签：</div>
                <div class="tag-list">
                    <span class="tag" :class="{selected:labelType==0}" @click="labelClick(0)">全部</span>
                    <span class="tag" :class="{selected:labelType==1}" @click="labelClick(1)">含红冲单</span>
                    <span class="tag" :class="{selected:labelType==2}" @click="labelClick(2)">含改付款码</span>
                    <span class="tag" :class="{selected:labelType==3}" @click="labelClick(3)">含退菜单</span>
                    <span class="tag" :class="{selected:labelType==4}" @click="labelClick(4)">含赠送单</span>
                    <span class="tag" :class="{selected:labelType==5}" @click="labelClick(5)">含折扣单</span>
                </div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table" ref="tableBox"  v-table-el-height="'tableEl'" >
                <el-table ref="tableEl" id="billtableEl" :data="tableListPage" border show-summary 
                :summary-method="getSummaries" 
                @current-change="handleCurrentChange"
                :row-class-name="tableRowClass"
                @sort-change="sortChange" 
                style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}" >
                    <el-table-column fixed="left" label="订单标签" min-width="70">
                        <template #default="scope">
                            <span class="tag tag-give" v-if="scope.row.IsGift">赠</span>
                            <span class="tag tag-ji" v-if="scope.row.IS_Refund">即</span>
                            <span class="tag tag-tao" v-if="scope.row.IS_Member">套</span>
                            <span class="tag tag-discount" v-if="scope.row.IsZk">折</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" label="打印次数" min-width="120" >
                        <el-table-column sortable="custom" prop="saleBill" label="消费清单" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.saleBill!=0">{{scope.row.saleBill}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="checkBill" label="结账清单" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.checkBill!=0">{{scope.row.checkBill}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column sortable="custom" fixed="left" prop="orderChannelName" label="订单渠道" min-width="130">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.orderChannelName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" fixed="left" prop="DeskName" label="台号" min-width="150">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.DeskName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="openDeskNo" label="开单号" min-width="160">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.openDeskNo}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_No" label="外卖单号" min-width="160" align="left"> </el-table-column> 
                    <el-table-column sortable="custom" prop="checkNo"  label="结账单号" min-width="160">
                        <template #default="scope">
                            <span class="link" @click="orderDetail(scope.row)">{{scope.row.checkNo}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="saleMoney" label="消费总额" min-width="90" data-format="number">
                        <template #default="scope">
                            <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.saleMoney!=0">{{scope.row.saleMoney}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column label="账单优惠">
                        <el-table-column sortable="custom" prop="ZKMoney" label="折扣额" min-width="70" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.ZKMoney!=0">{{scope.row.ZKMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="giftMoney" label="赠送额" min-width="70" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.giftMoney!=0">{{scope.row.giftMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="bargainPriceMoney" label="特价额" min-width="70" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.bargainPriceMoney!=0">{{scope.row.bargainPriceMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="otherMoney" label="其它优惠" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.otherMoney!=0">{{scope.row.otherMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="ZKTotal" label="优惠小计" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.ZKTotal!=0">{{scope.row.ZKTotal}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="CheckMoney" label="结账金额" min-width="80" data-format="number">
                        <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.CheckMoney!=0">{{scope.row.CheckMoney}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="XMoney" label="虚收金额" min-width="80" data-format="number">
                        <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.XMoney!=0">{{scope.row.XMoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="FactMoney" label="实收额" min-width="80" data-format="number">
                        <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.FactMoney!=0">{{scope.row.FactMoney}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_ManNum" label="人数" min-width="65" data-format="number">
                        <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.Eat_ManNum!=0">{{scope.row.Eat_ManNum}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_DeskNum" label="席数" min-width="65" data-format="number">
                        <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.Eat_DeskNum!=0">{{scope.row.Eat_DeskNum}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column label="其他金额">
                        <el-table-column sortable="custom" prop="ReturnMoney" label="退菜金额" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.ReturnMoney!=0">{{scope.row.ReturnMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="Service_Money" label="服务费" min-width="70" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.Service_Money!=0">{{scope.row.Service_Money}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable="custom" prop="Mantissa_Money" label="尾数调整" min-width="80" :formatter="priceFormat" data-format="number">
                            <template #default="scope">
                                <span class="rigths" @click="orderDetail(scope.row)" v-if="scope.row.Mantissa_Money!=0">{{scope.row.Mantissa_Money}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="EAT_PHASENAME" label="时段" min-width="70">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.EAT_PHASENAME}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_Saler" label="收银员" min-width="70">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_Saler}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_StationName" label="收银站点" min-width="80">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_StationName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_WaiterName" label="服务员" min-width="70">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_WaiterName}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_BTime" label="开始时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                    <el-table-column sortable="custom" prop="Eat_CheckTime" label="结束时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                </el-table>
            </div>
            <div class="block">
                <table-page-btn class="fy" :data="tableFilter" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>tableListPage=list" 
                    :page-size="pagesize"></table-page-btn>
            </div>
        </div>
        <!--订单渠道-->
        <modal-load :isShow="orderChanneShow">
            <order-Channel-Model :isShow="orderChanneShow" v-on:closeModel="orderChanneShow=false" :billType="1"  :OrderList="OrderList" :orderChannel="orderChannel" @orderChanneConfirm="orderChanneConfirm" ></order-Channel-Model>
        </modal-load>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
     </div>
</template>

<script>
import orderChannelModel from '../../../diningTable/model/orderChannelModel/orderChannelModel.vue'
import {newGuid} from '../../../../../common'
import resizeTable from '../tabelleAnpassen.js'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'

/**单据稽核报表 */
export default {
    components:{orderChannelModel,checkUserButtonModel},
    name:'billCheckReport',
    data(){
        return {
            total:1,//默认数据总数
            pagesize:9,//每页的数据条数
            currentPage:1,//默认开始页面
            istag: true,
            userInfo:'',
            /**表格数据 */ 
            tableList:[],
            tableListCopy:[],
            tableListPage:[],
            //排序
            sortable:null,
            //订单渠道id
            orderChannerId:[],
            //订单渠道名称
            orderChannelName:"",
            orderChanneShow:false,
            //渠道数据
            OrderList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            /**渠道集合 */
            orderChannel:[],
            selectContent:'', //搜索条件
            labelType:0,
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{}
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        /**筛选 或 排序 */
        tableFilter(){
            let list=Object.assign([],this.tableList);
            if(this.sortable && this.sortable.order){
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';

                list.order(this.sortable.prop,sorttype);
            } 
            return list;
        },
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.tempUserId='',this.tempOperatorName=''
        this.nowadays();
        this.labelType=0
        /**订单渠道 */
        this.$cacheData.OrderChannel().then((d)=>{
            this.orderChannel = d;
            this.orderChannel?.map(order=>{
                order.OrderChannelList.map(item=>{
                  item = Object.assign({},item, {is_type:0,Kind_AutoID:order.OrderChannelKind_AutoID})
                  this.OrderList.push(item)
                })
            })
        }).catch(()=>{
              this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(() => {
            /**拖动表宽度 */
            resizeTable();
        });
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                let height=this.$refs.tableBox.clientHeight - 130;
                this.pagesize=parseInt(height/50);
            }
        },
        /**分页 */
        tableRowClassName({rowIndex}){
            if (rowIndex === 0) {
            return 'th';
            }
            return '';
        },
        switchChange(){
          this.istag = !this.istag;
        },
        current_change(currentPage){
            this.currentPage = currentPage;
        },
        /**账单数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"单据稽核信息",
                list:this.tableList
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1)
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")]
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /**订单渠道点击 */
        orderChannelClick(){
            if(this.orderChanneShow==false){
                this.orderChanneShow =true
            }else{
                this.orderChanneShow =false
            }
        },
        /**订单渠道返回 */
        orderChanneConfirm(orderChannel){
            this.orderChannelName=''
            this.orderChannerId=[]
            orderChannel.map(item=>{
                if(item.is_type==1){
                    if(this.orderChannelName==''){
                        this.orderChannelName = item.OrderChannel_Name
                    }else{
                        this.orderChannelName = this.orderChannelName+','+item.OrderChannel_Name
                    }
                    this.orderChannerId.push(item.OrderChannel_AutoID)
                }
            })
            this.orderChanneShow =false
        },
        /**查询 */
        GetBillCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                CHANNEL_IDLIST:this.orderChannerId,//渠道id
                SearchStr:this.selectContent, //搜索字段
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.BillCheckReport",param,false).then((d)=>{
                loading.close();
                console.log('单据稽核返回:'+JSON.stringify(d))
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=[]
                    d.ResponseBody.map(item=>{
                        this.tableList.push(item)
                    })
                    this.tableListCopy = d.ResponseBody
                    this.setTablePageSize()
                    this.total= this.tableList.length;
                    this.currentPage=1
                    this.labelType=0
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    this.tableList=[],this.tableListCopy=[]
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        //穿透到 明细报表
        orderDetail(row){
            this.$emit("addFrame",{key:this.getTimestamp(new Date()),data:{checkNo:row.checkNo,Rpt_Date:row.Rpt_Date,Is_RedBill:row.IsRed},title:'订单详情',url:"billcheckdetailreport"});
        },
        getTimestamp(time) { //把时间日期转成时间戳
            return (new Date(time)).getTime() / 1000
        },
        /**标签点击 */
        labelClick(type){
            this.labelType=type
            this.tableList=[]
            this.tableListCopy.map(item=>{
                this.tableList.push(item)
            })
            if(type==0){
                this.setTablePageSize()
                this.total= this.tableList.length;
                this.currentPage=1
                return
            }
            if(type==1){
                for(let i = 0; i < this.tableList.length; i++){
                    if(this.tableList[i].IsRed==false){
                        this.tableList.splice(i,1)
                        i -= 1
                    }
                }
            }
            if(type==2){
                for(let i = 0; i < this.tableList.length; i++){
                    if(this.tableList[i].IsUpdatePay==false){
                        this.tableList.splice(i,1)
                        i -= 1
                    }
                }
            }
            if(type==3){
                for(let i = 0; i < this.tableList.length; i++){
                    if(this.tableList[i].IsReturn==false){
                        this.tableList.splice(i,1)
                        i -= 1
                    }
                }
            }
            if(type==4){
                for(let i = 0; i < this.tableList.length; i++){
                    if(this.tableList[i].IsGift==false){
                        this.tableList.splice(i,1)
                        i -= 1
                    }
                }
            }
            if(type==5){
                for(let i = 0; i < this.tableList.length; i++){
                    if(this.tableList[i].IsZk==false){
                        this.tableList.splice(i,1)
                        i -= 1
                    }
                }
            }
            this.setTablePageSize()
            this.total= this.tableList.length;
            this.currentPage=1
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.GetBillCheck()
        },
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
            if (index === 0) { // 只找第一列放合计
                sums[index] = '合计：'
                return
            }else if (index!==3&&index!==4&&index!==5 && index!==6 && index<=21){
                const values = this.tableList.map(item => Number(item[column.property]))
                if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    if(index==7){
                       return  this.total+'笔'
                    }
                    const value = Number(curr)
                    if (!isNaN(value)) {
                            return Number(Number(prev)+Number(curr)).toFixed(2)
                    } else {
                            return Number(prev).toFixed(2)
                    }
                },0)} else {
                    sums[index] = 'N/A'
                    }
                } else {
                    sums[index] = '--'
                    }
                })
            return sums
        },
        //奇偶行背景色不同
        setCurrent(row) {
            this.$refs.tableEl.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        tableRowClass({row, rowIndex}) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'success-row';
            }
            return '';
        },
        elConsole(e){
            console.log("输出：",e);
            return ""
        }
    }
}
</script>

<style lang="scss" >
@import './billCheckReport.scss'
</style>